import { useRouteLoaderData } from "@remix-run/react";
import type { CurrentUser } from "@fscrypto/domain";

export function useOptionalUser() {
  const { currentUser } = useRouteLoaderData("root") as { currentUser?: CurrentUser };
  return currentUser;
}

export function useUser() {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.",
    );
  }
  return maybeUser;
}
